import { Center, Stack, rem, Title, Text, Button } from '@mantine/core';
import classes from '@/style/LandingPage.module.css';

export function ReferralUpsellBlock() {
  return (
    <Center mt={rem(36)}>
      <Stack align="center" maw={1442}>
        <Title
          ta="center"
          fz={{ base: rem(32), md: rem(38) }}
          order={1}
          c="white"
          fw={700}
          lh={1.2}
          className={classes['header-title']}
        >
          Give Rewards, Get Rewarded
        </Title>
        <Text fz={{ base: rem(18), md: rem(20) }} maw={750} ta="center" lh="1.5">
          You can earn up to $30 per referral, plus you give two free months of the Basic paid plan to those you refer.
          Win-win for everyone!
        </Text>
        <Button component="a" href="./dashboard" size="md">
          Share Rewards Now
        </Button>
      </Stack>
    </Center>
  );
}
